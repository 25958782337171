import React, {Component} from 'react';
import SideNavListGroup from "./SideNavListGroup";
import {SideNavLink} from "./SideNavLink";
import {AdminLock} from "../context/auth/AdminFeature";
import businessIcon
    from '../images/15362-illustration-of-office-buildings-in-a-city-pv-icon.png';
import houseIcon from '../images/14532-illustration-of-a-house-or-icon.png';
import {DevelopmentOnly} from "../util/DevelopmentOnly";


class SideBarNav extends Component
{
    render()
    {
        return (<div className="col-xs-6 col-sm-3 sidebar-offcanvas"
                     role="navigation">
            <DevelopmentOnly>
                <SideNavListGroup name="Development">
                    <SideNavLink href="/error.html">
                        Test Error Page
                    </SideNavLink>
                    <SideNavLink href="/bootstrap.html">
                        Bootstrap Samples
                    </SideNavLink>
                </SideNavListGroup>
            </DevelopmentOnly>
            <SideNavListGroup name="Business Directory">
                <SideNavLink href="/index.html">
                    Directory
                    <img className="glyphicon-align-right" src={businessIcon}
                         alt="Business Directory"/>
                </SideNavLink>
                <SideNavLink href="/search.html">
                    Search
                </SideNavLink>
                <SideNavLink href="/addbusiness.html">
                    <AdminLock/>
                    Add Business
                </SideNavLink>
            </SideNavListGroup>
            <SideNavListGroup name="Landlord &amp; Tenant Listings">
                <SideNavLink href="/rentals.html" id='rentals'>
                    <AdminLock/>
                    Rentals
                    <img className="glyphicon-align-right" src={houseIcon}
                         alt="Rentals"/>
                </SideNavLink>
                <SideNavLink href="/tenants.html">
                    <AdminLock/>
                    Prospective Tenants
                </SideNavLink>
                <SideNavLink href="/add-rental.html">
                    <AdminLock/>
                    Add Rental
                </SideNavLink>
                <SideNavLink href="/add-tenant.html">
                    <AdminLock/>
                    Add Tenant
                </SideNavLink>
            </SideNavListGroup>
            <SideNavListGroup name="Resources">
                <SideNavLink href="/community-resources.html">
                    Community Resources
                </SideNavLink>
                <SideNavLink href="/community-forms.html">
                    Contracts and Forms
                </SideNavLink>
                <SideNavLink href="/rental-resources.html">
                    Landlords & Tenants
                </SideNavLink>
            </SideNavListGroup>
            <SideNavListGroup name="Our Community">
                <SideNavLink href="http://www.athabasca.ca/" external={true}>
                    Town of Athabasca
                </SideNavLink>
                <SideNavLink href="http://www.athabascacounty.com/"
                             external={true}>
                    County of Athabasca
                </SideNavLink>
                <SideNavLink href="http://www.boylealberta.com/"
                             external={true}>
                    Town of Boyle
                </SideNavLink>
            </SideNavListGroup>
        </div>)
    }
}

export default SideBarNav