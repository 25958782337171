import {default as React, Fragment, FunctionComponent, useContext} from "react";
import AuthenticatedContext, {AuthProxy} from "./AuthContext";
import openLockIcon from '../../images/baseline_lock_open_black_18dp.png';
import editIcon from '../../images/1447298310_icon-136-document-edit.svg';
import removeIcon from '../../images/1447298376_free-27-trash.svg';

/**
 * Display the children content only if you are authenticated and are an admin.
 *  Use this element to wrap content that should be displayed only when a
 * person is authenticated and in the admin role.
 *
 * @param children
 * @constructor
 */
const AdminFeature: FunctionComponent = ({children}) => {
    const context: AuthProxy = useContext(AuthenticatedContext);

    if (context.user.hasRole('admin'))
    {
        return <Fragment>
            {children}
        </Fragment>
    }
    else
    {
        return <Fragment/>
    }
};

/**
 * Displays content for users that are currently authenticated.
 *
 * @param children
 * @constructor
 */
const AuthFeature: FunctionComponent = ({children}) => {
    const context: AuthProxy = useContext(AuthenticatedContext);

    if (context.user.isAuthenticated())
    {
        return <Fragment>
            {children}
        </Fragment>
    }
    else
    {
        return <Fragment/>
    }
}

/**
 * Displays content for users that are currently **not** authenticated.
 *
 * @param children
 * @constructor
 */
const NonAuthFeature: FunctionComponent = ({children}) => {
    const context: AuthProxy = useContext(AuthenticatedContext);

    if (!context.user.isAuthenticated())
    {
        return <Fragment>
            {children}
        </Fragment>
    }
    else
    {
        return <Fragment/>
    }
}

/**
 * Displays a black material design lock icon.
 */
const AdminLock: FunctionComponent = () =>
    <AdminFeature>
        <img style={{textAlign: 'right'}} src={openLockIcon}
             alt="Unlocked Admin Features"/>
    </AdminFeature>;

/**
 * Displays an edit icon if you are logged in
 */
const AdminEdit: FunctionComponent = () =>
    <AdminFeature>
        <img style={{textAlign: 'right'}} src={editIcon} width="32" height="32"
             alt="Edit Admin Feature"/>
    </AdminFeature>;

/**
 * Displays an edit icon if you are logged in
 */
const AdminRemove: FunctionComponent = () =>
    <AdminFeature>
        <img style={{textAlign: 'right'}} src={removeIcon}
             width="32" height="32" alt="Remove Admin Feature"/>
    </AdminFeature>;

/**
 * Displays a note that submission is immediate while logged in
 */
const AdminAdd: FunctionComponent = () =>
    <AdminFeature>
        <p className="alert alert-warning">
            <strong>Important: </strong>
            Submission is immediate while you are logged in!
        </p>
    </AdminFeature>;

export {
    AdminFeature,
    AdminLock,
    AdminEdit,
    AdminAdd,
    AdminRemove,
    AuthFeature,
    NonAuthFeature
};