import React from 'react';
import AtHomeRouter from "./AtHomeRouter";

const MainContent = (props: any) => {
    return <div className="col-xs-12 col-sm-9"
             id="main-content">
            <AtHomeRouter/>
        </div>
};

export default MainContent;