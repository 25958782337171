import React from "react";
import * as PropTypes from "prop-types";

type NavGroupTypes = {
    name: string,
    children: any
}
function SideNavListGroup(props: NavGroupTypes)
{
    return <div className="list-group">
        <span className="list-group-item header">{props.name}</span>
        {props.children}
    </div>;
}

export default SideNavListGroup;

SideNavListGroup.propTypes = {onClick: PropTypes.func};