import * as React from "react";

export type User = {
    name?: string
    username?: string
    roles: string[]
}

export class UserState
{
    private readonly jwt: User | undefined;

    constructor(jwt?: User)
    {
        this.jwt = jwt;
    }

    isAuthenticated()
    {
        return !!this.jwt;
    }

    hasRole(role: string)
    {
        if (this.jwt)
            return this.jwt.roles.filter((r) => r === role).length === 1;
        else
            return false;
    }

    getName()
    {
        return this.jwt ? this.jwt.name : null;
    }

    getUsername()
    {
        return this.jwt ? this.jwt.username : null;
    }
}

export type AuthProxy = {
    /**
     * user is always available, even when not authenticated.
     */
    user: UserState,
    /**
     * Authenticates the user and sets up the session.
     *
     * @param username the username
     * @param password the password
     */
    login: (username: string, password: string) => void,
    /**
     * Logs the user out and set up an empty UserState object.
     */
    logout: () => void
}

const AuthenticatedContext = React.createContext(
    {user: new UserState()} as AuthProxy);

export default AuthenticatedContext;