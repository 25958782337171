import React from 'react';


const LoadingPage = () =>
    <div className="panel panel-info" style={{minHeight: '400px'}}>
        <div className="panel-heading">
            <h3 id="welcome-title"
                className="panel-title">Please Wait</h3>
        </div>
        <div className="panel-body">
            The page is taking awhile to load, please wait...
        </div>
    </div>
;

export default LoadingPage;