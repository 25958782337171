import React, {Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import LP from "./LoadingPage";

// This React.lazy with dynamic import function calls is for react
// code-splitting, so that webpack can split the files up into chunks that
// can be loaded on request, rather than packing them all into a single file
const AboutPage = React.lazy(() => import("../content/AboutPage"));
const AddBusinessPage = React.lazy(
    () => import("../content/directory/AddBusinessPage"));
const RentalsPage = React.lazy(() => import("../content/rentals/RentalsPage"));
const ProspectiveTenantsPage = React.lazy(
    () => import("../content/rentals/ProspectiveTenantsPage"));
const AddRental = React.lazy(() => import("../content/rentals/AddRental"));
const AddTenant = React.lazy(() => import("../content/rentals/AddTenant"));
const BootstrapPage = React.lazy(() => import("../content/BootstrapPage"));
const ContactPage = React.lazy(() => import("../content/ContactPage"));
const CategoryPage = React.lazy(
    () => import("../content/directory/CategoryPage"));
const CommunityResources = React.lazy(
    () => import("../content/resources/CommunityResources"));
const CommunityForms = React.lazy(
    () => import("../content/resources/CommunityForms"));
const DirectoryPage = React.lazy(
    () => import("../content/directory/DirectoryPage"));
const ErrorTestPage = React.lazy(() => import('../error/ErrorTestPage'));
const FourOhFour = React.lazy(() => import('../error/FourOhFour'));
const LoadingPage = React.lazy(() => import('./LoadingPage'));
const LoginPage = React.lazy(() => import("./login/LoginPage"));
const RentalResources = React.lazy(
    () => import("../content/resources/RentalResources"));
// resulting in a long page load.
const SearchPage = React.lazy(() => import("../content/directory/SearchPage"));
const WelcomePage = React.lazy(
    () => import('../content/directory/WelcomePage'));


function AtHomeRouter()
{
    return <Suspense fallback={<LP/>}>
        <Switch>
            <Route path="/" exact component={WelcomePage}/>
            <Route path="/addbusiness.html" component={AddBusinessPage}/>
            <Route path="/add-rental.html" component={AddRental}/>
            <Route path="/add-tenant.html" component={AddTenant}/>
            <Route path="/about.html" component={AboutPage}/>
            <Route path="/bootstrap.html" component={BootstrapPage}/>
            <Route path="/community-forms.html" component={CommunityForms}/>
            <Route path="/category-*.html" component={CategoryPage}/>
            <Route path="/community-resources.html"
                   component={CommunityResources}/>
            <Route path="/contact.html" component={ContactPage}/>
            <Route path="/error.html" component={ErrorTestPage}/>
            <Route path="/index.html" component={DirectoryPage}/>
            <Route path="/loading-page.html" component={LoadingPage}/>
            <Route path="/login.html" component={LoginPage}/>
            <Route path="/rentals.html" component={RentalsPage}/>
            <Route path="/rental-resources.html" component={RentalResources}/>
            <Route path="/search.html" component={SearchPage}/>
            {/* You can use this for making sure the loading page works*/}
            <Route path="/tenants.html" component={ProspectiveTenantsPage}/>
            <Route path="*">
                <FourOhFour/>
            </Route>
        </Switch>
    </Suspense>;
}

export default AtHomeRouter;