import * as React from "react";
import {FunctionComponent, useState} from "react";
import AuthenticatedContext, {AuthProxy, UserState} from "./AuthContext";
import {AuthenticatorFactory} from "./Authenticator";

const AuthenticatedState: FunctionComponent = ({children}) => {
    const [state, setState] = useState({user: new UserState()} as AuthProxy);
    const login = (username: string, password: string) => {
        console.log('auth: ', state.user, username, password);
        new AuthenticatorFactory().create(setState,
            state).authenticate(username, password);
    };

    const logout = () => {
        console.log('auth: ', state.user);
        setState({
            ...state,
            user: new UserState()
        });
    };

    return (
        <AuthenticatedContext.Provider value={{
            ...state, login, logout
        }}>
            {children}
        </AuthenticatedContext.Provider>
    );
};

export {AuthenticatedContext, AuthenticatedState};


