import React, {Component} from "react";
import {NavLink} from "react-router-dom";

type NavLinkProps = {
    external?: boolean
    children?: any
    href: string,
    id?: string
}

/**
 * Supports react-router-dom based navigation links, and plain html links.  If
 * you want a plain html link, it must be an external link.  You then set the
 * "external" prop.
 */
export class SideNavLink extends Component<NavLinkProps> {
    
    render()
    {
        if (this.props.external)
        {
            return <a href={this.props.href}
                      className='list-group-item internal'
                      data-toggle="offcanvas" id={this.props.id}>
                {this.props.children}
            </a>;

        }
        else
        {
            return <NavLink to={this.props.href}
                            className='list-group-item internal'
                            activeClassName='active'
                            data-toggle="offcanvas" id={this.props.id}>
                {this.props.children}
            </NavLink>;
        }
    }
}