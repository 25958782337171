import React from 'react';
import {NavLink} from "react-router-dom";
import AuthenticatedContext from "../context/auth/AuthContext";
import {Account} from "./Account";

const TopNav = () => <AuthenticatedContext.Consumer>
    {context => (
        <div className="navbar navbar-default navbar-fixed-top"
             role="navigation">
            <div className="container-fluid">
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle"
                            data-toggle="collapse"
                            data-target=".navbar-collapse">
                                <span
                                    className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"/>
                        <span className="icon-bar"/>
                        <span className="icon-bar"/>
                    </button>
                    <NavLink className="navbar-brand internal active"
                             to="/">
                        At Home</NavLink>
                </div>
                <div className="navbar-collapse collapse">
                    <ul className="nav navbar-nav">
                        <li>
                            <NavLink className="internal"
                                     to="/about.html">About</NavLink>
                        </li>
                        <li>
                            <NavLink to='/contact.html'
                                     className="internal">
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>

                    {Account(context)}
                </div>
            </div>
        </div>
    )}
</AuthenticatedContext.Consumer>;

export default TopNav;
