/*
 * Copyright 2020 Climbing Vine Technology Corp.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {AuthProxy, UserState} from "./AuthContext";
import {EnvFactory} from "ts-fakery";

interface Authenticator
{
    authenticate(username: string, password: string): void;
}

class BaseAuthenticator implements Authenticator
{
    protected readonly _setState: (value: (((prevState: AuthProxy) => AuthProxy) | AuthProxy)) => void;
    protected readonly _state: AuthProxy;

    constructor(setState: (value: (((prevState: AuthProxy) => AuthProxy) | AuthProxy)) => void,
        state: AuthProxy)
    {
        this._setState = setState;
        this._state = state;
    }

    authenticate(username: string, password: string): void
    {
        throw new Error('You should implement this as a subclass');
    }
}

class RestAuthenticator extends BaseAuthenticator
{
    authenticate(username: string, password: string)
    {
        const authState = new UserState({
            name: 'Not implemented',
            username: 'admin',
            roles: ['admin']
        });
        console.log('user: ', authState);
        this._setState({
            ...this._state,
            user: authState
        });
    }
}

class MemoryAuthenticator extends BaseAuthenticator
{
    authenticate(username: string, password: string): void
    {
        if (username === 'admin' && password === 'supersecret')
        {   // fake authentication
            const authState = new UserState({
                name: 'Admin',
                username: 'admin',
                roles: ['admin']
            });
            console.log('user: ', authState);
            this._setState({
                ...this._state,
                user: authState
            });
        }
        else if (username === 'user' && password === 'supersecret')
        {   // fake authentication
            const authState = new UserState({
                name: 'Rental User',
                username: 'rentaluser',
                roles: ['rentals']
            });
            console.log('user: ', authState);
            this._setState({
                ...this._state,
                user: authState
            });
        }
    }
}

/**
 * Creates an appropriate Authenticator, depending on whether you're in test,
 * development, or production.  We use a MemoryAuthenticator for
 * test/development because we don't want to have to depend on an API.
 */
export class AuthenticatorFactory
{
    @EnvFactory({
        "test": MemoryAuthenticator,
        "development": MemoryAuthenticator
    }, process.env.REACT_APP_environment)
    create(setState: (value: (((prevState: AuthProxy) => AuthProxy) | AuthProxy)) => void,
        state: AuthProxy): Authenticator
    {
        return new RestAuthenticator(setState, state);
    }
}

