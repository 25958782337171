import React, {Component} from 'react';
import TopNav from './nav/TopNav';
import './App.css';
import MainContent from "./infrastructure/MainContent";
import SideBarNav from "./nav/SidebarNav";
import {BrowserRouter as Router} from "react-router-dom";
import {AuthenticatedState} from "./context/auth/AuthState";

class App extends Component {
    render()
    {
        return (
            <AuthenticatedState>
                <Router>
                    <TopNav/>
                    <div className="container-fluid " id="container">
                        <div
                            className="row row-offcanvas row-offcanvas-right">

                            <p className="pull-right visible-xs">
                                <button type="button"
                                        className="btn btn-primary btn-xs"
                                        data-toggle="offcanvas"
                                        id="at-home-links">
                                    At Home Links &gt;&gt;
                                </button>
                            </p>

                            <MainContent/>
                            <SideBarNav/>
                        </div>

                        <hr/>

                        <footer>
                            <p>
                                <a href="http://www.climbingvine.ca">
                                    © Climbing Vine Technology Corp. 2014
                                </a>
                            </p>
                        </footer>

                    </div>
                </Router>
            </AuthenticatedState>
        );
    }
}

export default App;