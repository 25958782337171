import {AuthProxy} from "../context/auth/AuthContext";
import React from "react";
import {NavLink} from "react-router-dom";

let loggedInStyle = {
    color: 'red',
    display: 'block',
    padding: '10px 15px'
};


export function Account(context: AuthProxy)
{
    if (context.user.isAuthenticated())
    {
        return <ul className="navbar-nav nav navbar-right">
            <li>
                    <span className="internal"
                          id="welcome" style={loggedInStyle}>
                        Welcome {context.user.getName()}
                    </span>
            </li>
            <li>
                <button onClick={(e) => context.logout()}
                        id="top-logout"
                        className="btn-link">
                    Logout
                </button>
            </li>
        </ul>
    }
    else
    {
        return <ul className="navbar-nav nav navbar-right">
            <li>
                <NavLink id='top-login'
                         className="btn-link"
                         to="/login.html">
                    Login
                </NavLink>
            </li>
        </ul>
    }
}